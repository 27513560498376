<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="用户" prop="userId">
                <a-input v-model="queryParam.nickname" placeholder="请输入用户" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="现居地" prop="currentAddress">
                <a-input v-model="queryParam.currentAddress" placeholder="请输入现居地" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="审核状态" prop="sex">
                <a-select v-model="queryParam.approveStatus" placeholder="请选择" style="width: 100%" allow-clear>
                  <a-select-option :value="0">待审核</a-select-option>
                  <a-select-option :value="1">已审核</a-select-option>
                  <a-select-option :value="2">已驳回</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:base/info:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:base/info:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:base/info:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:base/info:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <approve-modal ref='approveModal' @ok="getList"></approve-modal>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :scroll="{x:true}"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
         <span slot="marriage" slot-scope="text, record">
          <dict-tag :options="dict.type['info_marriage']" :value="record.marriageId"/>
        </span>
        <span slot="nation" slot-scope="text, record">
          <dict-tag :options="dict.type['info_nation']" :value="record.nationId"/>
        </span>
        <span slot="career" slot-scope="text, record">
          <dict-tag :options="dict.type['info_career']" :value="record.careerId"/>
        </span>
        <span slot="education" slot-scope="text, record">
          <dict-tag :options="dict.type['info_education']" :value="record.educationId"/>
        </span>
        <span slot="income" slot-scope="text, record">
          <dict-tag :options="dict.type['info_income']" :value="record.incomeId"/>
        </span>
        <span slot="buyCar" slot-scope="text, record">
          <dict-tag :options="dict.type['info_buy_car']" :value="record.buyCarId"/>
        </span>
        <span slot="buy_house" slot-scope="text, record">
          <dict-tag :options="dict.type['info_buy_house']" :value="record.buyHouseId"/>
        </span>
        <span slot="expect_married" slot-scope="text, record">
          <dict-tag :options="dict.type['info_expect_married']" :value="record.expectMarriedId"/>
        </span>
        <span slot="except_child" slot-scope="text, record">
          <dict-tag :options="dict.type['info_except_child']" :value="record.exceptChildId"/>
        </span>
        <span slot="info_smoke" slot-scope="text, record">
          <dict-tag :options="dict.type['info_smoke']" :value="record.smokeId"/>
        </span>
        <span slot="info_drink" slot-scope="text, record">
          <dict-tag :options="dict.type['info_drink']" :value="record.drinkId"/>
        </span>
        <span slot="info_interest" slot-scope="text, record">
          <dict-tag :options="dict.type['info_interest']" :value="record.interestId"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="avatar" slot-scope="text, record">
           <template v-if="record.avatar">
            <img-preview  :src="record.avatar" :size="50"
                         style="margin-right: 8px;" />
          </template>
          <template v-else>未设置</template>
        </span>
        <span slot="photos" slot-scope="text, record">
           <template v-if="record.photos">
            <img-preview v-for="item in record.photos.split(',')" :src="item" :size="100"
                         style="margin-right: 8px;" />
          </template>
          <template v-else>未上传</template>
        </span>
        <span slot="realVideo" slot-scope="text, record">
            <template v-if="record.realVideo">
                <video :src=" record.realVideo" controls style="width:280px;height:200px" ></video>
              </template>
              <template v-else>未上传</template>
        </span>
        <span slot="operation" slot-scope="text, record">

          <a v-if='record.approveStatus == 0' @click="$refs.approveModal.audit(record)"  >
            <a-icon type="edit" />审核
          </a>

        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageBaseInfo,listBaseInfo, delBaseInfo } from '@/api/user/baseInfo'
import CreateForm from './modules/CreateForm'
import ApproveModal from './modules/ApproveModal.vue'
import { tableMixin } from '@/store/table-mixin'
import dictTag from '@/components/DictTag/index2.vue'
import ImgPreview from "@/components/crud/table/ImgPreview.vue";
import Template from '@/views/sms/template/index.vue'
export default {
  name: 'BaseInfo',
  components: {
    Template,ApproveModal,
    CreateForm,dictTag,ImgPreview
  },
  mixins: [tableMixin],
  dicts: ['info_marriage','info_nation','info_career','info_education','info_income','info_buy_car','info_buy_house','info_expect_married',
    'info_except_child','info_smoke','info_drink','info_interest'  ],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        nickname: null,
        currentAddress: null,
        address: null,
        approveStatus: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '#',
          dataIndex: '',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '用户',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'avatar' },
        },
        {
          title: '照片',
          dataIndex: 'photos',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'photos' },
        },
        {
          title: '实名视频',
          dataIndex: 'realVideo',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'realVideo' },
        },
        {
          title: '性别',
          dataIndex: 'sex',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if(value == 1){
              return "男"
            }else if(value == 2){
              return "女"
            }
            return "保密"
          }
        },
        {
          title: '生日',
          dataIndex: 'birthday',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '年龄',
          dataIndex: 'age',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '婚姻状态',
          dataIndex: 'marriageId',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'marriage' },
        },
        {
          title: '民族',
          dataIndex: 'nationId',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'nation' },
        },
        {
          title: '身高',
          dataIndex: 'height',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '体重',
          dataIndex: 'weight',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '星座',
          dataIndex: 'constellation',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否体制内',
          dataIndex: 'isSystem',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if(value == 0){
              return "否"
            }else if(value == 1){
              return "是"
            }
            return "保密"
          }
        },
        {
          title: '职业',
          dataIndex: 'careerId',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'career' },
        },
        {
          title: '学历',
          dataIndex: 'educationId',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'education' },
        },
        {
          title: '月收入',
          dataIndex: 'incomeId',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'income' },
        },

        {
          title: '现居地',
          dataIndex: 'currentAddress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '户籍地',
          dataIndex: 'address',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '购车',
          dataIndex: 'buyCarId',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'buyCar' },
        },
        {
          title: '购房',
          dataIndex: 'buyHouseId',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'buy_house' },
        },
        {
          title: '期望结婚时间',
          dataIndex: 'expectMarriedId',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'expect_married' },
        },
        {
          title: '是否要孩子',
          dataIndex: 'exceptChildId',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'except_child' },
        },
        {
          title: '吸烟',
          dataIndex: 'smokeId',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'info_smoke' },
        },
        {
          title: '喝酒',
          dataIndex: 'drinkId',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'info_drink' },
        },
        // {
        //   title: '兴趣爱好',
        //   dataIndex: 'interestId',
        //   ellipsis: true,
        //   align: 'center',
        //   scopedSlots: { customRender: 'info_interest' },
        // },
        {
          title: '审核状态',
          dataIndex: 'approveStatus',
          ellipsis: true,
          align: 'center' ,
          customRender: function (value) {
            if(value == 0){
              return "待审核"
            }else if(value == 1){
              return "审核通过"
            }
            return "审核驳回"
          }
        },
        {
          title: '审核失败原因',
          dataIndex: 'approveContent',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '150',
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {

    /** 查询用户基本信息列表 */
    getList () {
      this.loading = true
     pageBaseInfo(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        nickname: undefined,
        currentAddress: undefined,
        address: undefined,
        approveStatus: undefined,
        approveContent: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delBaseInfo(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/user-base-info/export', {
            ...that.queryParam
          }, `用户基本信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
